.promo__description
{
	display: flex;
	flex-direction: column;

	font-size: 20px;
	line-height: 27px;
	font-weight: $medium;
	text-transform: uppercase;
	letter-spacing: 2px;

	@media screen and ( $maxW_1920 )
	{
		font-size: calc( ( 20 - 14 ) / ( 1920 - 1280 ) * 100vw + 2px );
		line-height: calc( ( 27 - 19 ) / ( 1920 - 1280 ) * 100vw + 3px );
	}

	@media screen and ( $maxW_1280 )
	{
		font-size: 14px;
		line-height: 19px;
		letter-spacing: 1.5px;
	}

	@media screen and ( $maxW_1024 )
	{
		font-size: 14px;
		line-height: 19px;
	}
}