.about
{
	position: relative;
	z-index: 1;

	overflow: hidden;
	display: flex;
	flex-direction: column;
	align-items: center;

	padding-top: 360px;
	margin-bottom: 86px;

	background: 50% 503px url("/img/about/icons.svg") no-repeat;

	@media screen and ( $maxW_1680 )
	{
		background-position: 50% 485px;
		background-size: 1234px;
	}

	@media screen and ( $maxW_1440 )
	{
		background-position: 50% 470px;
		background-size: 1088px;
	}

	@media screen and ( $maxW_1280 )
	{
		background: 50% 468px url("/img/about/icons_667.svg") no-repeat;
	}

	@media screen and ( $maxW_1024 )
	{
		padding-top: 44px;
		margin-bottom: 56px;

		background-position: 50% 136px;
		background-size: 597px;
	}

	@media screen and ( $maxW_812 )
	{
		padding-top: 30px;
		margin-bottom: 60px;

		background-position: 50% 105px;
		background-size: 496px;
	}

	@media screen and ( $maxW_667 )
	{
		padding-top: 75px;

		background: 50% 141px url("/img/about/icons_320.svg") no-repeat;
	}
}