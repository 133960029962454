.tickets-short__title
{
	align-self: center;

	margin-bottom: 30px;

	font-size: 99px;
	line-height: 97px;
	font-weight: $bold;
	letter-spacing: -5px;
	text-transform: uppercase;
	text-align: center;

	color: $clr_lightgrey;

	@media screen and ( $maxW_1024 )
	{
		margin-bottom: 22px;

		font-size: 62px;
		line-height: 69px;
		letter-spacing: -3px;
		text-align: left;
	}

	@media screen and ( $maxW_667 )
	{
		order: -2;

		font-size: 38px;
		line-height: 41px;
	}
}