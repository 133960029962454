.success
{
	display: flex;
	align-items: flex-start;
	justify-content: center;

	width: 100%;
	padding-bottom: 257px;

	background: 50% calc(100% - 80px) url("/img/about/icons_667.svg") no-repeat;

	@media screen and ($maxW_1024)
	{
		background: 50% calc(100% - 90px) url("/img/about/icons_320.svg") no-repeat;
	}
}

.success__wrapper
{
	display: flex;
	flex-direction: column;
	align-items: center;

	width: 530px;
	padding-top: 80px;

	@media screen and ($maxW_667)
	{
		width: $width_maxW_667;
		max-width: $max_width_maxW_667;
	}
}

.success__pretitle
{
	margin-bottom: 21px;

	font-size: 28px;
	line-height: 32px;
	font-weight: $bold;
	letter-spacing: 3px;
	text-transform: uppercase;

	@media screen and ( $maxW_1024 )
	{
		margin-bottom: 7px;

		font-size: 18px;
		line-height: 20px;
		letter-spacing: 2px;
		white-space: nowrap;
	}
}

.success__title
{
	margin-bottom: 24px;

	font-size: 225px;
	line-height: 191px;
	font-weight: $bold;
	letter-spacing: -5px;
	text-transform: uppercase;

	color: $clr_orange;

	@media screen and ( $maxW_1024 )
	{
		margin-bottom: 18px;

		font-size: 132px;
		line-height: 147px;
	}

	@media screen and ( $maxW_667 )
	{
		margin-bottom: 7px;

		font-size: 62px;
		line-height: 69px;
	}
}

.success__text
{
	font-size: 14px;
	line-height: 18px;
	font-weight: $regular;
	letter-spacing: 0.3px;

	&:not(:last-child)
	{
		margin-bottom: 28px;
	}
}

.success__details
{
	color: $clr_grey;
}

.success__text-link
{
	text-decoration: underline;

	color: $clr_black;
}

.success__text-link_grey
{
	color: $clr_grey;
}

.success__link
{
	@extend %button_orange;

	margin-bottom: 28px;

	@media screen and ($maxW_667)
	{
		padding-left: 15px;
		padding-right: 15px;
	}

	span
	{
		@media screen and ($maxW_667)
		{
			display: none;
		}
	}
}