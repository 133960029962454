.tickets
{
	@extend %default_grid;

	position: relative;

	padding-top: 309px;
	padding-bottom: 112px;

	@media screen and ( $maxW_1024 )
	{
		padding-top: 72px;
		padding-bottom: 80px;
	}

	@media screen and ( $maxW_667 )
	{
		padding-bottom: 0;
	}

	&::before
	{
		position: absolute;
		top: 0;
		bottom: 0;
		right: calc(50% - 60vw);
		left: 50%;
		z-index: -1;

		content: "";

		background-color: $clr_orange;

		transform: skewX(-8deg);

		@media screen and ( $maxW_1680 )
		{
			left: 51%;
		}

		@media screen and ( $maxW_1024 )
		{
			content: none;
		}
	}
}