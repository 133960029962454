.promo__text
{
	font-size: 54px;
	line-height: 58px;
	font-weight: $bold;
	text-transform: uppercase;
	letter-spacing: -2.5px;

	color: $clr_lightgrey;

	@media screen and ( $maxW_1920 )
	{
		font-size: calc( ( 54 - 38 ) / ( 1920 - 1280 ) * 100vw + 6px );
		line-height: calc( ( 58 - 41 ) / ( 1920 - 1280 ) * 100vw + 7px );
	}

	@media screen and ( $maxW_1280 )
	{
		font-size: 38px;
		line-height: 41px;
		letter-spacing: -2px;
	}

	@media screen and ( $maxW_1024 )
	{
		margin-top: -12px;

		font-size: 32px;
		line-height: 34px;
	}

	@media screen and ( $maxW_812 )
	{
		font-size: calc( ( 32 - 26 ) / ( 812 - 667 ) * 100vw + -1.5px );
		line-height: calc( ( 34 - 28 ) / ( 812 - 667 ) * 100vw + 6.4px );
		letter-spacing: -1px;
	}

	@media screen and ( $maxW_667 )
	{
		position: relative;

		display: flex;
		flex-direction: column;

		margin-bottom: 20px;
		margin-top: 0;

		font-size: 20px;
		line-height: 25px;
	}

	@media screen and ( $maxW_414 )
	{
		font-size: calc( ( 20 - 18 ) / ( 414 - 320 ) * 100vw + 11px );
		line-height: calc( ( 25 - 23 ) / ( 414 - 320 ) * 100vw + 13px );
	}

	@media screen and ( $maxW_375 )
	{
		margin-bottom: 9px;
	}

	&::before
	{
		position: absolute;
		top: -23px;
		bottom: -16px;
		left: -57px;
		right: -79px;
		z-index: -1;

		content: "";

		background-color: $clr_black;

		transform: rotate(-1deg);

		@media screen and ( $maxW_1920 )
		{
			left: -44px;
			right: -57px;
		}

		@media screen and ( $maxW_1280 )
		{
			top: -20px;
			bottom: -7px;
			left: -45px;
			right: -43px;
		}

		@media screen and ( $maxW_1024 )
		{
			top: -27px;
			bottom: -4px;
			left: -40px;
			right: -40px;
		}

		@media screen and ( $maxW_812 )
		{
			top: -21px;
			bottom: -2px;
			left: -32px;
			right: -26px;
		}

		@media screen and ( $maxW_667 )
		{
			top: -19px;
			bottom: -8px;
			left: -40px;
			right: -33px;
		}

		@media screen and ( $maxW_414 )
		{
			top: -5vw;
			left: -9vw;
			right: -8vw;
		}
	}
}