.footer
{
	padding-top: 309px;
	padding-bottom: 28px;
	background-color: $clr_lightgrey;

	@media screen and ( $maxW_1920 )
	{
		padding-top: 15.7vw;
	}

	@media screen and ( $maxW_1280 )
	{
		padding-top: 220px;
	}

	@media screen and ( $maxW_1024 )
	{
		padding-top: 53px;

		background-color: transparent;
	}
}

.footer_preorder
{
	padding-top: 252px;

	background-color: $clr_black;

	@media screen and ( $maxW_1920 )
	{
		padding-top: 252px;
	}

	@media screen and ( $maxW_1024 )
	{
		padding-top: 0;
	}
}

.footer__wrapper
{
	@extend %default_grid;

	display: flex;
	align-items: center;
	justify-content: space-between;

	box-sizing: border-box;
	padding: 0 97px;

	@media screen and ( $maxW_1280 )
	{
		padding: 0;
	}

	@media screen and ( $maxW_667 )
	{
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
	}

}

.footer__creators
{
	display: flex;
	align-items: flex-start;
	flex-direction: column;

	font-size: 12px;
	line-height: 14px;
	font-weight: $medium;
	letter-spacing: 1.2px;
	text-transform: uppercase;

	@media screen and ( $maxW_1024 )
	{
		width: 240px;
	}

	@media screen and ( $maxW_667 )
	{
		margin-bottom: 20px;
	}
}

.footer__caption
{
	color: $clr_grey;
}

.footer__text
{
	@media screen and ( $maxW_1024 )
	{
		color: $clr_lightgrey;
	}
}

.footer__text_preorder
{
	color: $clr_lightgrey;
}

.footer__link
{
	font-size: 12px;
	line-height: 14px;
	font-weight: $medium;
	letter-spacing: 1.2px;
	text-transform: uppercase;

	color: $clr_grey;
}