.promo
{
	position: relative;
	z-index: 1;

	//overflow: hidden;
	display: flex;
	justify-content: center;

	width: 100%;

	&::after
	{
		position: absolute;
		top: 0;
		bottom: 0;
		left: -100vw;
		z-index: -2;

		width: 200vw;

		content: "";

		background: $clr_lightgrey;

		transform: skew(0deg, -3.6deg);

		@media screen and ( $maxW_1920 )
		{
			margin-top: -17px;

			transform: skew(0deg, -3.6deg) translateY(-10px);
		}

		@media screen and ( $maxW_1680 )
		{
			margin-top: 0;

			transform: skew(0deg, -3.6deg) translateY(-32px);
		}

		@media screen and ( $maxW_1280 )
		{
			transform: skew(0deg, -3.6deg) translateY(-43px);
		}

		@media screen and ( $maxW_1024 )
		{
			transform: skew(0deg, -3.6deg) translateY(-54px);
		}

		@media screen and ( $maxW_812 )
		{
			transform: skew(0deg, -3.6deg) translateY(-72px);
		}

		@media screen and ( $maxW_667 )
		{
			transform: skew(0deg, -3.6deg) translateY(-43px);
		}
	}
}