.promo__info-wrapper
{
	position: relative;
	z-index: 1;

	display: flex;
	flex-direction: column;
	align-items: flex-start;

	margin-left: 182px;

	@media screen and ( $maxW_1920 )
	{
		margin-left: calc( ( 182 - 100 ) / ( 1920 - 1280 ) * 100vw  - 64px );
	}

	@media screen and ( $maxW_1280 )
	{
		margin-left: -55px;
	}

	@media screen and ( $maxW_736)
	{
		margin-left: -38px;
	}

	@media screen and ( $maxW_667 )
	{
		position: static;

		align-items: center;

		margin-left: 0;
	}
}